import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {
	makeStyles
} from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Grid from "@mui/material/Grid";
import { ourOrange, ourOrange25, ourOrange80, ourOrange60, } from 'assets/jss/incept-sustainability-variables.jsx';
import { useMediaQuery } from 'react-responsive';

import Toolbar from '@mui/material/Toolbar';
import { IconButton } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import checkmark from 'assets/img/checkmark.png';
import guideIcon from 'assets/img/information.svg';
import moduleMenu from 'assets/img/moduleMenu.png';
import exampleicon from 'assets/img/exampleicon.png';
import activitynodataicon from 'assets/img/activitynodataicon.svg';
import activity from 'assets/img/activity.svg';
import { Link } from "react-router-dom";
import about from "assets/img/moduleIcon.svg";
import Plausible from 'plausible-tracker';
const { trackEvent } = Plausible();

const icons = [
	guideIcon,
	exampleicon,
	activitynodataicon,
	activity
]

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const mobile = useMediaQuery({ query: `(max-width: 600px)` });
        const isTablet = useMediaQuery({ query: '(max-width: 1224px)' })
		const isSmallLaptop = useMediaQuery({ query: '(max-width: 1440px)'})
        return <Component {...props} isMobile={mobile} isTablet={isTablet} isSmallLaptop={isSmallLaptop} />;
    }
}


const drawerWidth = 450;
const drawerWidthMobile = 320;
const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.down('md')]: {
			width: `${drawerWidthMobile}px`,
			flexShrink: 0,
		},
		[theme.breakpoints.up('sm')]: {
			width: `${drawerWidth}px`,
			flexShrink: 0,
		},
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		[theme.breakpoints.down('md')]: {
			width: `${drawerWidthMobile}px`,
		},
		[theme.breakpoints.up('sm')]: {
			width: `${drawerWidth}px`,
		},
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	closeMenuButton: {
		marginRight: 'auto',
		marginLeft: 0,
	},
	menuItemIcon: {
		color: '#97c05c',
	},
	listitemtext: {
		paddingLeft: "5px"
	},
	menuArrow: {
		color: ourOrange
	},
	sidebarTab: {
		width: '40px',
		height: '230px',
		backgroundColor: ourOrange80,
		position: 'fixed',
		top: '50%',
		color: 'white',
		fontWeight: '1000',
		fontSize: '20px',
		writingMode: 'vertical-rl',
		transform: 'translateY(-50%) rotate(180deg)',
		transition: 'transform 160ms ease, left 180ms ease',
		zIndex: theme.zIndex.drawer + 3,
		borderTopLeftRadius: '10px',
		borderBottomLeftRadius: '10px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		//padding: '3.5% 0 2% 0',
		left: '0',
	},
		sidebarTabOpen: {
		left: `${drawerWidthMobile + 130}px`,
	},
	boldT: {
		"& .MuiTypography-body1": {
			fontWeight: "bolder"
		},
		"& .MuiTypography-body2": {
			fontWeight: "200"
		}
	},
	arrowBack: {
		display: "flex",
		alignItems: "center",
		color: ourOrange,
	},
	arrowBackH: {
		borderRadius: "50%",
		cursor: "pointer",
		width: "3em",
		height: "2.9em",
		display: "flex",
		justifyContent: "center",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.04)"
		}
	}
}));

const ListItem = withStyles({
	root: {
		"&$selected": {
			backgroundColor: ourOrange25,
		},
		/*"&$selected:hover": {
			backgroundColor: "purple",
			color: "white",
			"& .MuiListItemIcon-root": {
				color: "white"
			}
		},*/
		"&:hover": {
			backgroundColor: ourOrange25,
		}
	},
	selected: {},
})(MuiListItem);

function SidebarGuide(props) {
	const classes = useStyles();
	const { window } = props;
	const [openItems, setOpen] = React.useState(Array(props.contentList.length).fill(false));
	const [mobileOpen, setOpenMenu] = React.useState(false);


	const drawer = (
		<div>
			<List>
				{props.contentList.map((list, index) => (
					<div key={index}>
						<ListItem key={index} button onClick={(e) => handleClick(index)}>
							<Grid container style={{ alignItems: "center" }}>
								<Grid item xs={1} md={1}>
									{(parseInt(index) + 1) + ". "}
								</Grid>
								<Grid item xs={11} md={11}>
									<div style={{ display: "flex", alignItems: "center" }}>
										<ListItemText inset primary={ReactHtmlParser(ReactHtmlParser(list.title))}
											classes={{ inset: classes.listitemtext, root: (index === props.currentStep) ? classes.boldT : "" }} />
										<img src={checkmark}
											style={{
												width: "15px",
												margin: "0 10px",
												visibility: (props.completedSectionsStep[index] === (Object.keys(list.sections).length)) ? "visible" : "hidden"
											}} />

										{openItems[index] ? <IconExpandLess className={classes.menuArrow} /> : <IconExpandMore className={classes.menuArrow} />}
									</div>
								</Grid>
							</Grid>
						</ListItem>
						<Collapse in={openItems[index]} timeout="auto" unmountOnExit>
							<Divider />
							<List component="div" style={{ margin: "20px" }} disablePadding>
								{
									Object.keys(list.sections).map(index2 => {
										return (
											<Link
												to={(props.iskb) ? `${props.backNavPath}/${index + 1}/${props.typemode}/` : `${props.backNavPath}/${index + 1}/act/`}
												key={index2}
												style={{ color: "inherit" }}
												onClick={(e) => handleDrawerToggle(2)}>
												<ListItem key={index2} selected={(props.lastOpened.section === index2) ? true : false} >
													<Grid key={index2} container style={{ alignItems: "center" }}>
														<Grid item xs={1} md={1}>
															<img
																src={icons[list.sections[index2].iconIndex]}
																width="25px"
																alt=''
															/>
														</Grid>
														<Grid item xs={11} md={11}>
															<Grid container style={{ alignItems: "center" }}>
																<Grid item xs={11} md={11}>
																	<ListItemText inset
																		primary={list.sections[index2].title}
																		style={{ paddingLeft: "10px" }} />
																</Grid>
																<Grid item xs={1} md={1}>
																	<div style={{ display: "flex", alignItems: "center" }}>
																		<img
																			src={checkmark}
																			style={{ width: "15px", margin: "0 10px", visibility: (list.sections[index2].completed) ? "visible" : "hidden" }}
																			alt="Completed section"
																			title="Completed section"
																		/>
																	</div>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</ListItem>
											</Link>
										);
									})
								}
							</List>
						</Collapse>
					</div>
				))}
			</List>
		</div>
	);

	function handleClick(i) {
		let newArr = [...openItems];
		newArr[i] = !newArr[i];
		setOpen(newArr);
	}

	function openCurrentStep() {
		let newArr = [...openItems];
		newArr[props.currentStep] = true;
		setOpen(newArr);
	}

	function handleDrawerToggle(origin = 0) {
		if (!mobileOpen) {
			openCurrentStep();
		}
		setOpenMenu(!mobileOpen);
		if (origin === 1)
			trackEvent('Open Menu');
		else if (origin === 2)
			trackEvent('Go to step');
		else if (origin === 3)
			trackEvent('Go to Module overview');
	};

	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<div className={classes.root}>
			{props.isMobile &&
				<Toolbar style={{display:"inline-flex", height: "20px", position: "fixed", backgroundColor: "white"}}>
					<IconButton
							color="inherit"
							aria-label="Open drawer"
							edge="start"
							onClick={(e) => handleDrawerToggle(1)}
							size="large">
							<img src={moduleMenu}
							style={{width:"30px"}} />

					</IconButton>
				</Toolbar>
			}
			<CssBaseline />
			<Box>
				<Box
					component="nav"
					sm={{ width: { sm: drawerWidthMobile, md: drawerWidth }, flexShrink: { sm: 0 } }}
					aria-label="mailbox folders"
				>
					{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
					<Drawer
						container={container}
						variant="temporary"
						open={mobileOpen}
						onClose={(e) => handleDrawerToggle()}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{!props.isMobile &&
						<div 
							className={`${classes.sidebarTab} ${mobileOpen ? classes.sidebarTabOpen : ''}`}
							onClick={(e) => handleDrawerToggle(1)}
						>
							<span>Navigation</span>
						</div>}
						<Grid container style={{ margin: "30px 16px" }}>
							<Grid item xs={10} md={10}>
								<h3 style={{ margin: "0" }}>Module overview</h3>
								<Link
									to={(props.iskb) ? `${props.backNavPath}/1/${props.typemode}/` : `${props.backNavPath}/0/act/`}
									style={{ color: "inherit" }}
									onClick={(e) => handleDrawerToggle(3)}>
									<span>{props.title}</span>
									<img
										src={about}
										alt="about"
										width="15"
										style={{ cursor: "pointer", margin: "0 0 5px 5px" }}
									/>
								</Link>
							</Grid>
							<Grid item xs={2} md={2}
								className={classes.arrowBack}>
								{props.isMobile && <div className={classes.arrowBackH} onClick={(e) => handleDrawerToggle()}>
									<ArrowBackIosIcon style={{ transform: "translate(3px, 7px)" }} />
								</div>}
							</Grid>
						</Grid>
						<Divider />
						{drawer}
					</Drawer>
				</Box>
			</Box>
		{!props.isMobile &&
			<div 
				className={`${classes.sidebarTab} ${mobileOpen ? classes.sidebarTabOpen : ''}`}
				onClick={(e) => handleDrawerToggle(1)}
			>
				<span>Navigation</span>
			</div>}
		</div>
	);
}
SidebarGuide.propTypes = {
	container: PropTypes.object,
	window: PropTypes.func
};
export default withMyHook(SidebarGuide);