/*!
 * Description: this file contains the main base component for
 *  the renderind of the whole web app. It handles the switchRoutes
 *
 * Components: Dashboard
 */
import React from "react";
import PropTypes from "prop-types";
import clientConfig from 'client-config.js';
import { Switch, Route, Redirect } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import Footer from "components/Footer/Footer.jsx";
//Error
import ErrorBoundary from "components/Log/ErrorBoundary.jsx";

import routes from "routes.js";
import jwt from "jwt-decode";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

// import logo from "assets/img/logo_incept.png";
import logo2 from "assets/img/incept_logo.svg";
import axios from 'axios';
import { get_company_user_info, isAuthenticated, node_dashboard } from 'assets/jss/incept-sustainability-variables.jsx';
import Grid from "@mui/material/Grid";
//Translations
import i18n from "i18next";
import i18next from "i18next";

import { default as CustomModal } from "components/Modal/Modal.jsx";
import ActionButton from "components/CustomButtons/ActionButton";
//Translations
import { withTranslation } from 'react-i18next';
import analytics_submenu from "assets/img/analytics_submenu.png"
import KnowledgeBasePage from "views/KnowledgeBase/KnowledgeBasePage.jsx";
import ElearningIcon from "assets/img/navbar/ModuleIcon.png";
import ElearningIconBlack from "assets/img/navbar/ModuleIconBlack.png";
import GuidesPage from "views/Guides/GuidesPage.jsx";

function switchRoutes(databackend, loading, isExpired, routesSt) {
	return <Switch>
		{routesSt.map((prop, key) => {
			if (prop.layout === "/admin") {
				let params = "";
				if (prop.path.includes("/knowledgebasesub"))
					params = "/:id";
				return (
					<Route
						path={prop.layout + prop.path + params}
						/*component={prop.component}*/
						render={(props) => (
							<ErrorBoundary {...props}><prop.component {...props}
								databackend={databackend} loading={loading} isExpired={isExpired} key={key} /></ErrorBoundary>
						)}
						key={key}
					/>
				);
			}
			return null;
		})}
		<Redirect from="/admin" to="/admin/dashboard" />
	</Switch>;
};

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		var decodedtoken = "";
		var redi = false;
		if (!isAuthenticated()) {
			redi = true;
		}
		else
			decodedtoken = jwt(localStorage.getItem('token'));
		console.log('DECODED TOKEN: ', decodedtoken)
		let roleaux = "";
		if (decodedtoken !== "") {
			roleaux = decodedtoken.role;
			/*if (decodedtoken.role.includes("Manager"))
				roleaux = "Manager";*/
		}
		this.state = {
			color: "blue",
			fixedClasses: "dropdown show",
			mobileOpen: false,
			iscustomManager: (decodedtoken.role === "Custom Manager" || decodedtoken.role === "Custom Learner Manager") ? true : false,
			role: roleaux,
			redirect: redi,
			loadingAd: true,
			showLeaveModal: false,
			routesSt: routes,
			showModTypes: false,
			assessmentCategories: [],
			dataBackend: {
				company_id: "",
				logoimg: "",
				message: "",
				video: "",
				tip: "",
				ideas: "",
				completedm: "",
				ideasall: [],
				languages: [],
				totalmod: 0,
				complemod: 0,
				nUserDepartment: 0,
				completedN: 0,
				nUserCompleted: 0,
				completedAllN: 0,
				customManager: null,
				sustainLevel: "",
				sustainLevelLeft: "",
				engage_message: "",
				username: "",
				news: {
					title: "",
					description: "",
					date: "",
					url: ""
				}
			}
		}
		if (localStorage.getItem('token') !== null) {
			this.state.loggedin = true;
		}
	}
	isexpired = () => {
		this.setState({
			redirect: true,
		});
	}
	/*logout*/
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to="/login" />;
		}
	};
	logoutprofile = () => {
		//Alert
		if (this.state.mobileOpen)
			this.setState({ mobileOpen: !this.state.mobileOpen });
		this.setState({ showLeaveModal: true });
	}

	handleHideLeaveModal = (leavingModule = false) => {
		if (leavingModule) {
			//Clean certain tickets
			//localStorage.clear();
			localStorage.removeItem('hasEverDisplayedTutorialModal');
			localStorage.removeItem('is_kbarray');
			localStorage.removeItem('token');
			localStorage.removeItem('is_compside');
			localStorage.removeItem('userName');
			localStorage.removeItem('i18nextLng');
			//localStorage.removeItem('userName');
			this.setState({
				redirect: true,
			});
		}
		this.setState({ showLeaveModal: false });
	};

	mainPanel = React.createRef();
	/*handleImageClick = image => {
	  this.setState({ image: image });
	};*/

	/*For mobile responsiveness*/
	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};

	/*resizeFunction = () => {
		if (window.innerWidth >= 960) {
			this.setState({ mobileOpen: false });
		}
	};*/

	//Gets message and logo
	async getgeneralinfo() {
		/*if (navigator.platform.indexOf("Win") > -1) {
		  ps = new PerfectScrollbar(this.mainPanel.current);
		}
		window.addEventListener("resize", this.resizeFunction);*/
		if (!isAuthenticated()) {
			this.setState({
				redirect: true,
			});
			return;
		}
		this.setState({ loadingAd: true });
		const token = localStorage.getItem('token');
		const authstr = 'Bearer ' + token;
		const useLang = i18n.language;
		const endpointwp = get_company_user_info + "company?wpml_language=" + useLang;
		const nodeendpoint = node_dashboard + "company&lang=" + useLang;
		let address = nodeendpoint;
		axios.get(address, { headers: { Authorization: authstr } })
			.then(response => {
				var stateCopy = {};
				stateCopy["company_id"] = response.data.company_id;
				stateCopy["username"] = response.data.username;
				stateCopy["message"] = response.data.message;
				stateCopy["message_learner_manager"] = response.data.messageManager;
				stateCopy["logoimg"] = response.data.logo;
				stateCopy["videoE"] = response.data.video_employee;
				stateCopy["videoM"] = response.data.video_manager;
				stateCopy["tip"] = (response.data.tip.length > 0) ? { "tip": response.data.tip[0].description, "tipimg": response.data.tip[0].image } : {};
				stateCopy["completedm"] = response.data.completedm;
				stateCopy["totalmod"] = response.data.totalmod;
				stateCopy["complemod"] = response.data.complemod;
				stateCopy["languages"] = response.data.languages;
				stateCopy["engage_message"] = response.data.engage_message;
				stateCopy["showKnowledgeBase"] = response.data.company_id !== "60" ? response.data.showKnowledgeBase : 0;
				stateCopy["kbarray"] = response.data.knowledgeBaseCategories;
				stateCopy["news"] = response.data.news;
				stateCopy["sustainLevel"] = response.data.sustainLevel;
				stateCopy["sustainLevelLeft"] = response.data.sustainLevelLeft;
				stateCopy["defaultLanguage"] = response.data.default_language;
				stateCopy["greenbox"] = response.data.greenbox;
				stateCopy["whitebox"] = response.data.whitebox;
				stateCopy["languageBasedUserCountry"] = response.data.is_language_based_on_user_country;
				if (stateCopy["showKnowledgeBase"] === 1 && localStorage.getItem("is_kbarray") === null) {
					//this.updateRoutes(stateCopy["kbarray"]);
					localStorage.setItem("is_kbarray", JSON.stringify(response.data.knowledgeBaseCategories));
					window.location.reload();
				}
				stateCopy["customManager"] = (response.data.customManager !== undefined) ? response.data.customManager : null;
				this.setState({ dataBackend: stateCopy });
				this.setState({ loadingAd: false });


			})
			.catch(err => {
				this.setState({ error: err, loading: false });
				this.setState({ loadingAd: false });
			})
	}
	componentDidMount() {
		this.getgeneralinfo();
		this.updateRoutes2();
		i18next.on('languageChanged', () => this.getgeneralinfo());
		this.fetchAssessmentCategories();
	}

	componentDidUpdate(e) {
		if (e.history.location.pathname !== e.location.pathname) {
			this.mainPanel.current.scrollTop = 0;
			if (this.state.mobileOpen) {
				this.setState({ mobileOpen: false });
			}
		}
	}

	fetchAssessmentCategories = async () => {
		try {
		  const token = localStorage.getItem('token');
		  const authstr = 'Bearer ' + token;
		  const response = await axios.get(`${clientConfig.nodesiteUrl}assessments/categories`, {
			headers: { Authorization: authstr }
		  });
		  this.setState({ assessmentCategories: response.data });
		} catch (error) {
		  console.error('Error fetching assessment categories:', error);
		}
	};

	updateRoutes = (arrayKB) => {
		let newroutes2 = this.state.routesSt;
		const indexKB = this.state.routesSt.findIndex(el => el.name === "Knowledge Base");
		arrayKB.reverse().map((obj, index) => {
			let newrt = {
				path: "/knowledgebasesub",
				id: obj.id,
				name: obj.name,
				trans: i18n.t("E-learnings categories"),
				nameGen: "E-learnings categories",
				icon: analytics_submenu,
				component: KnowledgeBasePage,
				roleD: "Both",
				norder: index,
				layout: "/admin",
				submenu: true,
				invisible: true
			};
			newroutes2 = [...newroutes2.slice(0, indexKB + 1), newrt, ...newroutes2.slice(indexKB + 1)];
		});
		this.setState({ routesSt: newroutes2 });
	}

	updateRoutes2 = () => {
		let moDroutes = localStorage.getItem("is_compside");
		if (moDroutes === "undefined")
			return;
		let mdarray0 = JSON.parse(moDroutes);
		if (mdarray0 === null)
			return;
		let mdarray = mdarray0.modCategories;
		let newroutes2 = this.state.routesSt;
		const indexMOD = this.state.routesSt.findIndex(el => el.name === "E-Learnings");
		if (mdarray !== undefined && mdarray.length > 0) {
			mdarray.reverse().map((obj, index) => {
				let newrt = {
					path: "/guides",
					id: obj.id,
					name: obj.name,
					nameGen: "E-Learnings Categories",
					trans: i18n.t("E-Learnings"),
					icon: [ElearningIconBlack, ElearningIcon],
					component: GuidesPage,
					roleD: "Both",
					layout: "/admin",
					submenu: true,
					invisible: true
				};
				newroutes2 = [...newroutes2.slice(0, indexMOD + 1), newrt, ...newroutes2.slice(indexMOD + 1)];
			});
			this.setState({ showModTypes: true })
		}
		this.setState({ routesSt: newroutes2 });
	}

	render() {
		const { classes, t, ...rest } = this.props;
		return (

			<div className={classes.wrapper}>
				{this.renderRedirect()}

				{/* This is for the sidebar menu */}
				<Sidebar
					isMobile={false}
					routes={this.state.routesSt}
					// TODO SORRY FOR THIS, BUT WE SHOULD FIND A BETTER WAY TO HANDLE THE KNOWLEDGE BASE COMPANY ID CHECK, THE CODE IS CRAP!!!
					showKB={(this.state.dataBackend["showKnowledgeBase"] === 1 || localStorage.getItem("is_kbarray") !== null) && this.state.dataBackend.company_id !== "60" }
					showModTypes={this.state.showModTypes}
					logo={this.state.dataBackend["logoimg"]}
					logo2={logo2}
					languages={(this.state.dataBackend["languages"] !== null) ? this.state.dataBackend["languages"] : []}
					role={this.state.role}
					iscustomManager={this.state.iscustomManager}
					handleDrawerToggle={this.handleDrawerToggle}
					open={this.state.mobileOpen}
					color={this.state.color}
					logoutprofile={this.logoutprofile}
					renderRedirect={this.renderRedirect}
					defaultLanguage={(this.state.dataBackend["defaultLanguage"] !== null) ? this.state.dataBackend["defaultLanguage"] : ""}
					languageBasedUserCountry={(this.state.dataBackend["languageBasedUserCountry"] !== null) ? this.state.dataBackend["languageBasedUserCountry"] : false}
					companyId={this.state.dataBackend.company_id}
					assessmentCategories={this.state.assessmentCategories}
					{...rest}
				/>
				<div className={classes.mainPanel} ref={this.mainPanel} id="dashboardWrapper_isx">
					<Navbar
						routes={this.state.routesSt}
						iscustomManager={this.state.iscustomManager}
						customManager={this.state.dataBackend["customManager"]}
						handleDrawerToggle={this.handleDrawerToggle}
						{...rest}
					/>
					{/* this handles when the views are changed */}
					<div className={classes.content}>
						<div>{switchRoutes(this.state.dataBackend, this.state.loadingAd, this.isexpired, this.state.routesSt)}</div>
					</div>
					<Footer />
				</div>
				<CustomModal
					show={this.state.showLeaveModal}
					onHide={this.handleHideLeaveModal}
				>
					<h5 style={{ textAlign: "center", marginTop: '50px', marginBottom: '50px' }}>{t('Are you sure you want to leave?')}</h5>
					<Grid container spacing={2}>
						<Grid item sm style={{ textAlign: "right" }}>
							<ActionButton size="large" onClick={() => this.handleHideLeaveModal(true)}>{t('Yes')}</ActionButton>
						</Grid>
						<Grid item sm>
							<ActionButton size="large" lighten onClick={() => this.handleHideLeaveModal()}>{t('Cancel')}</ActionButton>
						</Grid>
					</Grid>
				</CustomModal>
			</div>
		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(dashboardStyle)(Dashboard));
