/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState, useEffect, forwardRef, useLayoutEffect } from 'react';
import { Redirect, useHistory  } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import guidesStyle from "assets/jss/incept-custom-css.jsx";
import axios from "axios";
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import { isAuthenticated, n_comp_info, n_comp_reg } from "assets/jss/incept-sustainability-variables.jsx";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
//Translations
import {TextField} from '@mui/material';
import incept_logo from "assets/img/incept_logo.svg";
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import InputForm from "components/CustomInput/InputForm.jsx";
import SelectInput from "components/CustomInput/SelectInput.jsx";
import DropDown from 'components/CustomInput/DropDown';
import MenuItem from '@mui/material/MenuItem';
import { Form, FormGroup, Input, Label } from "reactstrap";
import Grid from "@mui/material/Grid";
import { default as CustomModal } from "components/Modal/TermsAndConditionModal.jsx";
import { ourDarkGreen, ourOrange60, ourOrange } from "assets/jss/incept-sustainability-variables.jsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ourBlue  } from 'assets/jss/incept-sustainability-variables';
import validator from 'validator';
import loadinggif from "assets/img/loading.gif";
import ReactHtmlParser from "react-html-parser";
import Button from "components/CustomButtons/Button.jsx";

import { withTranslation, Trans,useTranslation } from 'react-i18next';

const roles = [
    { id: 1, name: "Admin" },
    { id: 2, name: "User" },
    { id: 3, name: "Guest" }
]



const pStyle = {
    label: {
        color: "#57493a",
        fontWeight: "bold",
        display: "block",
    },

    title: {
        width: "100%",
        marginBottom: "1rem",
    },

    button: {
        padding: "0.5rem",
        borderRadius: "2rem",
        marginBottom: "20rem",
    },

    termsConditionLink: {
        textDecoration: "underline",
        margin: 0,
        color: ourDarkGreen,
        fontWeight: "bold",
    },
    linkPointer: {
        cursor: "pointer",
    }
};

const InputFormProps = {
    marginBottom: "0"
};

const useStyles = (theme) => ({
    containerother: {
        // paddingLeft: "40px",
        marginRight: "auto",
        marginLeft: "auto",
        [theme.breakpoints.down('md')]: {
            padding: "0 25px",
        },
    },
    wrapper: {
        /*marginTop: "-9.5rem",*/
        // marginRight: "2rem",
        marginBottom: "40px",
        [theme.breakpoints.down('md')]: {
            margin: "0",
        },
    },

    contentWraper: {
        height: "90vh",
        width: "90vw",
        flexBasis: "60%",
        display: "flex",
        "@media (max-width: 768px)": {
            flexFlow: "column wrap",
        },
    },

    logoContainer: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        marginLeft: 'auto',
        alignItems: "end",
        flexBasis: "40%",
        "@media (max-width: 768px)": {
            justifyContent: "center",
        },
    },

    redirect: {
        marginTop: '2rem',
        alignSelf: 'center',
        marginLeft: '13rem',
        "@media (max-width: 768px)": {
            marginLeft: '14rem',
        },
    },

    redirectTitle: {
        color: ourBlue,
        fontSize: '20px',
        fontWeight: 'bold'
    },

    GridContainer: {
        padding: "0 15% 0 2%",
        display: "flex",
        flexBasis: "60%",
        height: "60vh",
        justifySelf: "center",
        alignSelf: "center",
    },
	loadingOverlay: {
		width: "100%",
		height: "100%",
		position: "fixed",
		overflow: "auto"
	},
	errorMessage: {
		color: "red",
		fontStyle: "italic",
		marginTop: "0.4rem"
	},
	goodMessagePass: {
		color: "green",
		fontStyle: "italic",
		marginTop: "-0.7rem"
	}
});


const SignUp = (props) => {
   
    const mediaQuery = useMediaQuery('(max-width:768px)');
	let beforeRedirectTimeout;

    const { match, classes, location, handleClick } = props;
    const { t, i18n } = useTranslation();

	const [formTitle, setFormTitle] = useState("");
	const [formDescription, setformDescription] = useState("");
	const [jobtypes, setjobtypes] = useState([]);
	const [countries, setcountries] = useState([]);
	const [messageAfter, setmessageAfter] = useState("");
	const [termsandConditions, settermsandConditions] = useState("");

	const [submittingCompany, setSubmittingCompany] = useState(true);
    const [companyName, setCompanyName] = useState("");
    const [role, setRole] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [country, setCountry] = useState(0);
    const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [aggreedConditions, setAggreedConditions] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [formSent, setFormSent] = useState(false);
	const [errorpassword, setErrorPassword] = useState("");
	const [errorMessagePass, setErrorMessagePass] = useState("");
	const [goodMessagePass, setGoodMessagePass] = useState("");
	const [redirectLogin, setRedirectLogin] = useState(false);

	const searchParams = new URLSearchParams(location.search);
    let tokencompany = {};
    // Redirecting to the new Next powered registration page
    useLayoutEffect(()=> {
        if (searchParams.has("tokenid")) {
            tokencompany = searchParams.get("tokenid");
        }
        const tokenid = tokencompany;
        window.location.href = `https://next.inceptsustainability.com/register?parentToken=${tokenid}`
    }, [])
	

    const handleChange = (setInput, val) => {
		document.getElementById("errordiv").style.display = "none";
        setInput(val);
    };

    const history = useHistory();

	useEffect(() => {
		// You need to restrict it at some point
		// This is just dummy code and should be replaced by actual
		if (submittingCompany) {		
			getncomplpack();
			if (searchParams.has("lang")) {
				i18n.changeLanguage(searchParams.get("lang"));
			}
		}
	  }, []);

	//Get info about company
	const getncomplpack = async (fromid=0,source="") => {
		const urlapi = n_comp_info + "infoRegCompany";
		const insdata = {
			tokencompany: tokencompany
		};
		axios
			.get(urlapi,{ params: insdata })
			.then((response) => {
				console.log(response.data.data);
				const data = response.data.data.result;
				setFormTitle(data.title);
				setformDescription(data.description);
				setjobtypes(data.jobtypes);
				setcountries(data.geographies);
				setmessageAfter(data.textaftersubmit);
				settermsandConditions(data.termsandconditions);
				setSubmittingCompany(false);
			})
			.catch((err) => {
				console.log(err.response.data.message);
				console.log(err);
				setSubmittingCompany(false);
			});
	};

	const handleValidation = () => {
		const inputs = [companyName, firstName, lastName, email, country, role, password, passwordConfirm];
		let messageError = "";

		inputs.map((input) => {
			if (input == "" || validator.isEmpty(input.toString(),{ ignore_whitespace: true })) {
				messageError = "Please fill/select all fields";
			}
		});

		if (!validateCompanyName(companyName)) {
			messageError = "Please enter a valid company name";
		}
		else if (!validateName(firstName)) {
			messageError = "Please enter a valid first name";
		}
		else if (!validateName(lastName)) {
			messageError = "Please enter a valid last name";
		}
		else if (!validateEmail(email)) {
			messageError = "Please enter a valid email address";
		}
		else if (!validatePassword(password)) {
			messageError = "Please enter a valid password";
		}
		else if (password != passwordConfirm) {
			messageError = "Passwords do not match";
		}

		if (messageError != "") {
			setErrorMessage(messageError);
			document.getElementById("errordiv").style.display = "block";
			return false;
		}
		return true;
	}

	const confirmPassword = (pass) => {
		setPasswordConfirm(pass);
		if (password != pass) {
			setErrorPassword("Passwords do not match");
			return false;
		}
		else {
			setErrorPassword("");
			return true;
		}
	}

	const validateEmail = (email) => {
		var re = /\S+@\S+\.\S+/;
		return re.test(email) && validator.isEmail(email);
	}

	const validatePassword = (pass) => {
		setPassword(pass);
		let value = pass;
        if (validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setErrorMessagePass('');
			setGoodMessagePass('Strong Password');
			return true;
        } else {
            setErrorMessagePass('Is Not Strong Password. Min 8 characters, 1 lowercase, 1 uppercase, 1 number, 1 symbol');
			setGoodMessagePass('');
			return false;
        }
    }

	const validateCompanyName = (value) => {
		var regExp = /[a-zA-Z]/g;
					
		if(!regExp.test(value))
		{
			return false;
		}

		//Company name can only contain letters, numbers, spaces, and dashes
		if (validator.isAlphanumeric(value, 'en-US', { ignore: ' &-æøå/.\'' })) {
			return true;
		} else {
			return false;
		}
	}

	const validateName = (name) => {
		//Name can only contain letters 
		let value = name;
		if (validator.isAlpha(value, 'en-US', { ignore: ' áéíóúæøå' })) {
			return true;
		} else {
			return false;
		}
	}

	const renderRedirect = () => {
        if (redirectLogin) {
            return <Redirect to="/login" />;
        }
	};


	const submitNewCompany = (e) => {
		if (!handleValidation()) {
			//setnewLabel(false);
		} else {
			document.getElementById("errordiv").style.display = "none";
			setSubmittingCompany(true);

			const insdata = {
				companyName: companyName,
				company_tokenid: tokenid,
				firstname: firstName,
				lastname: lastName,
				email: email,
				password: password,
				countryname: country,
				jobtype: role,
			};

			//post
			axios
				.post(n_comp_reg + "asdep", insdata)
				.then((res) => {
					console.log(res);
					document.getElementById("thankyoudiv").style.display = "block";
					setSubmittingCompany(false);
					setFormSent(true);
					i18n.changeLanguage("en");
					beforeRedirectTimeout = setTimeout(function() {
						setRedirectLogin(true);
					}.bind(this), 5000);
				})
				.catch((err) => {
					setErrorMessage(err.response.data.message);
					document.getElementById("errordiv").style.display = "block";
					setSubmittingCompany(false);					
				});
		}
	}


    const customModalProps = {
        showModal,
        setShowModal,
		textModal: ReactHtmlParser(termsandConditions),
        buttonAction: () => {
            setAggreedConditions(true);
            setShowModal(!showModal);
        },
    };

    const returnInputValues = () => {
        submitNewCompany();
    }



    return (
        <LoadingOverlay
            active={submittingCompany}
			className={classes.loadingOverlay}
            spinner={<BounceLoader />}>
			{renderRedirect()}
            <div className={classes.containerother}>
                <div className={classes.wrapper}>
                    <div className={classes.contentWraper} style={{ paddingBottom: mediaQuery ? "0" : "10vh" }}>
                        <div className={classes.logoContainer}>
                            <img
                                width="50%"
                                height="50%"
                                src={incept_logo}
                                alt="incept logo"
                            />
                            <div className={classes.redirect}>
                                <h2>Login form</h2>
                                <p>You already have an account?</p>
                                <Button
                                    style={{
                                        backgroundColor: ourOrange,
                                        padding: '8px',
                                        width: '8rem',
                                        fontWeight: '600',
                                        borderRadius: '50px',
                                        "&:hover": {
                                            backgroundColor: ourOrange60,
                                        }
                                    }}
                                    color="info"
                                    onClick={() => history.push('https://app.inceptsustainability.com/login')}
                                    id="loginbutton"
                                    >
                                        Login
                                </Button> 
                            </div> 
                        </div>
                        <div className={classes.GridContainer}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{paddingTop:"0"}}>
                                    <div style={{ ...pStyle.title, display: mediaQuery ? '' : 'flex', flexFlow: mediaQuery ? '' : 'column wrap', textAlign: mediaQuery ? 'center' : '' }}>
                                        <h2>{formTitle}</h2>
                                        <p>{formDescription}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Label style={pStyle.label}
                                        htmlFor="Company Name">{t("Company name")}</Label>
                                    <InputForm
                                        type="text"
                                        name="company_name"
                                        id="company_name"
										disabled={formSent}
                                        onChange={(e) => handleChange(setCompanyName, e.target.value)}
                                        {...InputFormProps}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Label style={pStyle.label}
                                        htmlFor="Role">{t("Job type")}</Label>
                                    <DropDown value={role}
                                        paddingShort={true}
										disabled={formSent}
                                        onChange={(event) => setRole(event.target.value)}
                                        style={{ height: "3rem", cursor: "pointer" }}
                                    >
                                        <MenuItem value={0}>
                                            <span style={{ color: ourBlue }}>Select</span>
                                        </MenuItem>
                                        {jobtypes.map(
                                            (jobtype) => <MenuItem key={jobtype} value={jobtype}>{jobtype}</MenuItem>
                                        )}
                                    </DropDown>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Label style={pStyle.label}
                                        htmlFor="First Name">{t("First name")}</Label>
                                    <InputForm
                                        type="text"
                                        name="first_name"
										disabled={formSent}
                                        id="first_name"
                                        onChange={(e) => handleChange(setFirstName, e.target.value.trim())}
                                        {...InputFormProps}
                                    />

                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Label style={pStyle.label}
                                        htmlFor="Last Name">{t("Last name")}</Label>
                                    <InputForm
                                        type="text"
                                        name="last_name"
                                        id="last_name"
										disabled={formSent}
                                        onChange={(e) => handleChange(setLastName, e.target.value.trim())}
                                        {...InputFormProps}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Label style={pStyle.label}
                                        htmlFor="Geography">{t("Geography")}</Label>
                                    <DropDown 
										value={country}
                                        paddingShort={true}
										disabled={formSent}
                                        onChange={(event) => setCountry(event.target.value)}
                                        style={{ height: "3rem", cursor: "pointer"}}
                                    >
                                        <MenuItem value={0}>
                                            <span style={{ color: ourBlue }}>Select</span>
                                        </MenuItem>
                                        {countries.map(
                                            (countryitem) => <MenuItem key={countryitem} value={countryitem}>{countryitem}</MenuItem>
                                        )}
                                    </DropDown>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Label style={pStyle.label}
                                        htmlFor="Email">{t("E-mail")}</Label>
                                    <InputForm
                                        type="email"
                                        name="email"
										disabled={formSent}
                                        id="email"
                                        onChange={(e) => handleChange(setEmail, e.target.value)}
                                        {...InputFormProps}
                                    />
                                </Grid>
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<Label style={pStyle.label}
                                        htmlFor="Geography">{t("Password")}</Label>
									<InputForm
                                        type="password"
                                        name="password"
										disabled={formSent}
                                        id="password"
                                        onChange={(e) => validatePassword(e.target.value)}
                                        {...InputFormProps}
                                    />
									<p className={classes.errorMessage}>{errorMessagePass}</p>
									<p className={classes.goodMessagePass}>{goodMessagePass}</p>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<Label style={pStyle.label}
                                        htmlFor="Geography">{t("Confirm Password")}</Label>
									<InputForm
                                        type="password"
                                        name="confirmPassword"
										disabled={formSent}
                                        id="confirmPassword"
                                        onChange={(e) => confirmPassword(e.target.value)}
                                        {...InputFormProps}
                                    />
									<p className={classes.errorMessage}>{errorpassword}</p>
								</Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            paddingRight: "5px"
                                        }}
                                    >
                                        <a style={pStyle.linkPointer}
                                            onClick={(e) => setShowModal(true)}
                                        >
                                            <p style={pStyle.termsConditionLink}>
                                                {t("I accept the Terms and Conditions")}
                                            </p>
                                        </a>
                                        <input
                                            id="acceptCheckbox"
                                            type="checkbox"
                                            style={{
                                                transform: "scale(2)",
                                                filter: "hue-rotate(-30deg)",
                                                marginLeft: "30px",
												cursor: "pointer"
                                            }}
											disabled={formSent}
                                            onChange={() => setAggreedConditions(!aggreedConditions)
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
									<div
										id="thankyoudiv"
										style={{
											color: "green",
											textAlign: "right",
											display: "none",
										}}
									>
										{messageAfter}
										<img
										src={loadinggif}
										width="25"
										alt=""
										style={{ marginLeft: "5px" }}
										/>
									</div>
									<div
										id="errordiv"
										style={{
											color: "red",
											textAlign: "right",
											display: "none",
										}}
									>
										{errorMessage}
									</div>                                    
                                    <div style={{ display: mediaQuery ? '' : 'flex', justifyContent: mediaQuery ? '' : 'flex-end', textAlign: mediaQuery ? 'center' : '' }}>
                                        <ActionButton
                                            size="large"
                                            disabled={!aggreedConditions || formSent}
                                            onClick={(e) => returnInputValues()
                                            }
                                        >
                                            {t("Sign up")}
                                        </ActionButton>
                                    </div>
                                </Grid>
                            </Grid>                           
                            <CustomModal customModalProps={customModalProps} />
                        </div>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
}
export default
    withStyles(
        (theme) => ({
            ...useStyles(theme),
            ...guidesStyle(theme)
        }),
        { withTheme: true },
    )(SignUp);
