/*!
* Description: this file contains the component for
*  the top part of the modules (title)
* Components: GuideTopContainer
*/
import React from "react";
import withStyles from "@mui/styles/withStyles";
import { IconButton } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { ourBlue, ourOrange, ourGreen, help_email, isAuthenticated } from 'assets/jss/incept-sustainability-variables.jsx';
import axios from "axios";


import icon1 from 'assets/img/icon2.png';
import icon10 from 'assets/img/icon5.png';
import icon7 from "assets/img/icon7.png";
import icon9 from 'assets/img/icon9.png';

import icon1_1 from 'assets/img/icon2.png';
import icon10_1 from 'assets/img/icon5.png';
import icon9_1 from 'assets/img/icon9.png';

import exitIcon from 'assets/img/guide_top_navbar/exit.png';
import questionIcon from 'assets/img/guide_top_navbar/question.png';

import imageExplanation from 'assets/img/explainImage.png';
import tutorial1 from 'assets/img/tutorial1.png';
import tutorial2 from 'assets/img/tutorial2.png';
import tutorial3 from 'assets/img/tutorial3.png';
import tutorial4 from 'assets/img/tutorial4.png';
import tutorial5 from 'assets/img/tutorial5.png';
import tutorial6 from 'assets/img/tutorial6.png';
import tutorial7 from 'assets/img/tutorial7.png';
import tutorial8 from 'assets/img/tutorial8.png';

import ActionButton from "components/CustomButtons/ActionButton";
import Link from '@mui/material/Link';

//Used for first time login
import SidebarGuide from "components/Sidebar/SidebarGuide.js";
import { default as CustomModal } from "components/Modal/Modal.jsx";
import loadinggif from "assets/img/loading.gif";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@mui/material/MenuItem';
import Grid from "@mui/material/Grid";
//Translations
import { withTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const tutorialImgs = [
	tutorial1,
	tutorial2,
	tutorial3,
	tutorial4,
	tutorial5,
	tutorial6
];

const styles = (theme) => ({
	definewidth: {
		//maxWidth: "1760px",
		position: "relative",
		// background: "white",
		height: "55px",
		zIndex: "200",
		"@media only screen and (max-width: 960px)": {
			height: "55px",
		},
	},
	root: {
		position: "fixed",
		width: "100%",
		zIndex: "199",
		overflow: "hidden",
		maxWidth: "1500px",
		left: "50%",
		transform: "translate(-50%, 0)",
		height: "55px",
		"@media only screen and (max-width: 960px)": {
			paddingBottom: "15px",
		},
		marginLeft: "3em",
		/*height: "45px"*/
	},
	headerIcons: {
		position: "absolute",
		top: "0",
		right: "0",
		zIndex: "1000",
		textAlign: "right",
		paddingRight: "60px",
		"@media only screen and (max-width: 960px)": {
			marginTop: "5px"
		},
	},
	notMobile: {
		paddingLeft: "20px",
		position: "inherit",
		width: "250px"
	},
	iconNavBack: {
		height: '35px',
		marginTop: '10px',
		color: ourBlue,
		cursor: "pointer",
		marginLeft: '20px',
		/*[theme.breakpoints.up("sm")]: {
			marginRight: "20px"
		}*/
	},
	iconTutorial: {
		height: '40px',
	},
	iconTutorialBlock: {
		display: 'inline-block',
		width: '40px',
		marginRight: '15px',
		textAlign: 'center'
	},
	iconTextContainer: {
		fontWeight: "bold",
		marginBottom: "10px"
	},
	title: {
		display: 'inline-block',
		color: ourGreen,
		textTransform: "uppercase",
		"& h2": {
			fontWeight: "500",
		},
	},
	modalBox: {
		"& .modal-content": {
			borderRadius: "2rem",
		}
	},
	button2: {
		borderRadius: "20px",
		backgroundColor: "rgb(255, 111, 105)",
		color: "white",
		border: "1px",

		cursor: "pointer",
		fontSize: "13px",
		padding: "2px 25px",
		marginLeft: "1rem",
		marginBottom: "1rem",
		[theme.breakpoints.down('md')]: {
			marginBottom: "1rem",
		},
	},
	textarea1: {
		backgroundColor: "rgba(255,255,255,0.5)",
		position: "relative",
		zIndex: "2",
		marginBottom: "20px",
		borderRadius: "2rem",
		borderColor: "grey",
		resize: "none"
	},
	modalCancelButton: {
		right: "0",
		position: "relative",
		zIndex: "3",
		padding: "0px",
		float: "right"
	},
	/*topPattern: {
		position: "relative",
		width: "335px",
		float: "right",
		"@media only screen and (max-width: 960px)": {
		  display: "none",
		},
	},*/
	errorfield: {
		color: "red",
		textAlign: "center"
	},
	successfield: {
		color: "green",
		textAlign: "center"
	},
	footertext: {
		fontStyle: "italic",
		fontSize: "11px",
		textAlign: "right"
	},
	rightlabel: {
		textAlign: "right",
		paddingRight: "10px",
		margin: "auto"
	}
});

function withMyHook(Component) {
	return function WrappedComponent(props) {
		const mobile = useMediaQuery({ query: `(max-width: 1024px)` });
		const isTablet = useMediaQuery({ query: '(max-width: 1224px)' })
		const isSmallLaptop = useMediaQuery({ query: '(max-width: 1440px)' })
		return <Component {...props} isMobile={mobile} isTablet={isTablet} isSmallLaptop={isSmallLaptop} />;
	}
}

class GuideTopContainer extends React.Component {
	constructor(props) {
		super(props);
		//Check if has ever logged in at least once, and then set it to true
		const hasEverDisplayedTutorialModal = JSON.parse(localStorage.getItem('hasEverDisplayedTutorialModal') ?? 'false');
		localStorage.setItem('hasEverDisplayedTutorialModal', JSON.stringify(true));

		this.state = {
			showLeaveModal: false,
			showReminderModal: false,
			showTutorialModal: !hasEverDisplayedTutorialModal,
			modalTitle: "",
			modalId: "",
			allideas: "",
			helpcontent: "",
			afterMailMessage: "",
			afterMail: false,
			afterMailError: false,
			dashactive: false,
			ideaactive: false,
			questionactive: false,
			valueDD: 0,
			mobileOpen: false,
			limitText: false
		};

		this.timeoutIdeas = 0;
	}

	showHideModal = (idkey, title = "") => {
		if (this.state.showReminderModal) {
			clearTimeout(this.timeoutIdeas);
		}
		this.setState({
			showReminderModal: !this.state.showReminderModal,
			modalTitle: title,
			modalId: idkey,
			questionactive: false,
			ideaactive: false,
			afterMail: false
		});

		if (idkey === "mod2") {
			this.setState({ questionactive: !this.state.questionactive, afterMail: false });
		}

		if (idkey === "mod1")
			this.setState({ ideaactive: !this.state.ideaactive });
	};

	handleShowTutorialModal = (title) => {
		this.setState({ showTutorialModal: true, showReminderModal: false });
	};

	handleHideTutorialModal = () => {
		this.setState({ questionactive: false });
		this.setState({ showTutorialModal: false });
	};

	handleHideLeaveModal = () => {
		this.setState({ showLeaveModal: false });
	};

	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};

	handleChange(e, id) {
		console.log("textValue", e.target.value)
		const limitText = e.target.value;
		if (limitText.length <= 10) {
			this.setState({ limitText: true });
		}
		else {
			this.setState({ limitText: false });
		}
		if (id === "mod2") {
			this.setState({ helpcontent: e.target.value });
		}
		else {
			const allideas = e.target.value;
			this.setState({ allideas: allideas });
		}
	}

	sendquestion() {
		if (!isAuthenticated()) {
			this.props.isExpired();
			return false;
		}
		document.getElementById("loadinggd2").style.display = "block";
		const formdata = {
			subject: "Question from Module " + this.props.titleMod,
			text: this.state.helpcontent,
		};

		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;

		this.setState({ loading: true }, () => {
			axios
				.post(help_email, formdata, { headers: { Authorization: authstr } })
				.then((res) => {
					//console.log(res);
					document.getElementById("loadinggd2").style.display = "none";
					this.setState({ afterMail: true, helpcontent: "", afterMailMessage: "Question sent." });
				})
				.catch((err) => {
					if (err.response !== undefined)
						console.log(err.response.data.message);
					else
						console.log(err);
					this.setState({ afterMail: true, afterMailError: true, afterMailMessage: "Error", });
					document.getElementById("loadinggd2").style.display = "none";
				});
		});
	}

	handleChange2(e, id) {
		if (id === "mod1") {
			if (this.state.allideas === "") {
				alert("Text field cannot be empty");
				return;
			}
			if (this.state.limitText == false) {
				const ideaObj = {
					idea: this.state.allideas,
					label: this.state.valueDD
				};
				this.props.saveresloc("Ideas", ideaObj, this.props.indexk + 1);
				if (document.getElementById("thankudiv2")) {
					document.getElementById("thankudiv2").style.display = "block";
					document.getElementById("ideabox2").style.display = "none";

					this.timeoutIdeas = setTimeout(function () {
						if (document.getElementById("thankudiv2")) {
							document.getElementById("thankudiv2").style.display = "none";
							document.getElementById("ideabox2").style.display = "block";
						}
					}, 2000);
					this.setState({ allideas: "" });
				}
			}
			else {
				this.setState({ limitText: true });
			}
		}
		else {
			if (this.state.helpcontent === "") {
				alert("Text field cannot be empty");
				return;
			}
			this.sendquestion();
		}
	}

	onChangeDD(event) {
		const answerIndexSelected = parseInt(event.target.value);
		this.setState({ valueDD: answerIndexSelected });
	}

	render() {
		const { classes, lastOpened, currentStep, titleMod, backNavPath,
			saveres, isdashactive, labels, totalexercises_ans, showRecapModal,
			contentList, totalexercises, t, completedSectionsStep } = this.props;

		return (

			<div className={classes.definewidth} style={{ display: "flex", background: this.props.isMobile ? 'white' : "", zIndex: this.props.isMobile ? '1000' : ''}}>
				{
					<SidebarGuide
						contentList={contentList}
						lastOpened={lastOpened}
						currentStep={currentStep}
						completedSectionsStep={completedSectionsStep}
						title={titleMod}
						iskb={this.props.iskb}
						typemode={this.props.typemode}
						backNavPath={backNavPath}
					/>
				}
				<div className={classes.root} style={{ display: "inline-block", background: this.props.isMobile ? 'white' : "" }}>
					{/* {!this.props.isMobile && !this.props.iskb &&
						<div
							style={{ height: "56px", display: "flex", marginTop: "10px" }}
							className={classes.notMobile}
						>
							<h3 style={{ color: ourGreen, flex: "1", margin: "0 0" }}>{totalexercises_ans + "/" + totalexercises}</h3>
							<label style={{ color: "black", flex: "1 1 30%", fontSize: "0.9em", marginLeft: "10px" }}>{t("Feedback activities submitted")}</label>
						</div>
					} */}
					{/*<img
						src={inceptLogo}
						alt="back to guide overview icon"
						className={classes.iconNavBack}
						style={{ height: "45px", marginTop: "0", cursor: "auto" }}
					/>*/}
					<div className={classes.headerIcons}>
						{
							this.props.isMobile &&

							<>
								<img
									src={isdashactive ? icon1_1 : icon1}
									alt="back to guide overview icon"
									onClick={!this.props.iskb ? saveres : () => showRecapModal(this.props.iskb)}
									title={t("Go to dashboard")}
									className={classes.iconNavBack}
								/>
								<img
									title={t("Add new comment or idea")}
									id={"mod1"}
									src={this.state.ideaactive ? icon10_1 : icon10}
									alt="new idea icon"
									onClick={(e) => this.showHideModal(e.target.id, e.target.title)}
									className={classes.iconNavBack}
								/>
								<img
									src={this.state.questionactive ? icon9_1 : icon9}
									title={t("Help")}
									id={"mod2"}
									alt="report problem icon"
									onClick={(e) => this.showHideModal(e.target.id, e.target.title, { showActionForTutorialModal: true })}
									className={classes.iconNavBack}
								/>
							</>
						}
					</div>


				</div>
				<div className={classes.headerIcons}>
					{
						!this.props.isMobile &&
						<>
							<img
								src={exitIcon}
								alt="back to guide overview icon"
								onClick={!this.props.iskb ? saveres : () => showRecapModal(this.props.iskb)}
								title="Go to dashboard"
								className={classes.iconNavBack}
							/>
							<img
								src={questionIcon}
								title={t("Help")}
								id={"mod2"}
								alt="report problem icon"
								onClick={(e) => this.showHideModal(e.target.id, e.target.title, { showActionForTutorialModal: true })}
								className={classes.iconNavBack}
							/>
						</>
					}
				</div>
				<Modal
					id="2"
					show={this.state.showReminderModal}
					onHide={this.showHideModal}
					centered
					size="lg"
					className={classes.modalBox}
				>
					<Modal.Body>
						<IconButton
							onClick={() => this.showHideModal()}
							className={classes.modalCancelButton}
							size="large">
							<img
								src={icon7}
								alt="cancel icon"
								style={{ width: "40px", height: "40px" }}
							/>
						</IconButton>
						<div style={{ padding: "2rem" }}>
							{this.state.modalId === "mod2" && <h4>{this.state.modalTitle}</h4>}
							{
								this.state.modalId === "mod2" && false &&
								<Link component="button" onClick={() => this.handleShowTutorialModal()}>{t("Getting Started?")}</Link>
							}
							<p style={{ fontWeight: "bold", marginBottom: "20px", marginTop: "1rem" }}>
								{this.state.modalId === "mod2" ? t("Want to report a problem?")
									: this.state.modalTitle
								}
							</p>
							<div id="ideabox2">
								<Form>
									<Form.Control as="textarea"
										value={this.state.modalId === "mod2" ? this.state.helpcontent : this.state.allideas}
										onChange={(e) => this.handleChange(e, this.state.modalId)}
										rows="8" className={classes.textarea1} />
									{this.state.limitText == true ? <p style={{ color: "red" }}>{t("Please submit a valid idea (a minimum of 10 characters)")}</p> : ""}
									{this.state.modalId !== "mod2" &&
										<div>
											{(labels !== undefined && labels.length > 0) && <Grid container>
												<Grid item xs={12} md={8} className={classes.rightlabel}>
													<p style={{ marginBottom: "0" }}>{t("Please select the category") + ":"}</p>
												</Grid>
												<Grid item xs={12} md={4}>
													<DropDown
														value={this.state.valueDD}
														color="black"
														paddingShort={true}
														onChange={(event) => this.onChangeDD(event)}
													>
														{labels.map(
															(item) => <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
														)}
														<MenuItem value={0}>
															<em>Other</em>
														</MenuItem>
													</DropDown>
												</Grid>
											</Grid>}
											<p className={classes.footertext}>
												{t('no personal data',
													{
														defaultValue: 'Please keep your submission anonymous, do not write any personal data in your submission.'
													})}
											</p>
										</div>
									}
									{this.state.afterMail && <p className={this.state.afterMailError ? classes.errorfield : classes.successfield}>
										{this.state.afterMailMessage}
									</p>}
									<div id="loadinggd2" style={{ display: "none" }}>
										<img
											className="responsive"
											src={loadinggif}
											width="25"
											alt=""
											style={{ marginBottom: "20px" }}
										/>
									</div>
									<ActionButton
										onClick={(e) => this.handleChange2(e, this.state.modalId)}
										style={{ float: "right" }}
										className={classes.submitButton}>
										{t('Submit')}
									</ActionButton>
								</Form>
							</div>
							<div style={{ margin: "30px 0 60px 0", textAlign: "center", display: "none", position: "relative", zIndex: "9999" }}
								id="thankudiv2">
								<h2 style={{ color: ourGreen }}>{t('Thank you!')} </h2>
								<h3>{t('Your comment has been received') + "."}</h3>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<CustomModal
					show={false}
					onHide={() => this.handleHideTutorialModal()}
					noPattern={true}
					customSize={true}
				>
					<h2>{t('Ready to get started?') + " See the images bellow explaining how to go through the module:"}</h2>
					<div>
						<Carousel showArrows={true}>
							<div>
								<img src={tutorial1} />
							</div>
							<div>
								<img src={tutorial2} />
							</div>
							<div>
								<img src={tutorial3} />
							</div>
							<div>
								<img src={tutorial4} />
							</div>
							<div>
								<img src={tutorial5} />
							</div>
							<div>
								<img src={tutorial6} />
							</div>
							<div>
								<img src={tutorial7} />
							</div>
							<div>
								<img src={tutorial8} />
							</div>
						</Carousel>
					</div>
					{/**find this overview in... */}
				</CustomModal>
			</div>
		);
	}
}

GuideTopContainer.defaultProps = {
	title: 'Top title for this guide',
	backNavPath: '/'
};

export default withTranslation()(withMyHook(withStyles(styles)(GuideTopContainer)));