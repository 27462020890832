/*!
 * Description: this file contains the main base component for
 *  the rendering of the modules
 * Components: GuideSectionManager, Guide
 */

import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// UI Lab
import { TimelineSeparator, TimelineContent, TimelineDot } from "@mui/lab";
import TimelineItem from "components/Timeline/TimelineItem";
import Timeline from "components/Timeline/Timeline";

import GuideTopContainer from "components/GuidePage/GuideTopContainer.js";
import GuideStandardCardStyled from "components/GuidePage/GuideStandardCard.js";
import GuideFlipCardsSection from "components/GuidePage/GuideFlipCardsSection.js";
import WithoutOneStatic from "components/GuidePage/GuideWithoutOne.js";
import GuideVideoCard from "components/GuidePage/GuideVideoCard.js";
import GuideExercise from "components/GuidePage/GuideExercise.js";
import GuideExample from "components/GuidePage/GuideExample.js";
import GuideExampleInteractive from "components/GuidePage/GuideExampleInteractive.js";
import GuideExampleInteractive_b from "components/GuidePage/GuideExampleInteractive_b.js";
import GuideRecap from "components/GuidePage/GuideRecap.js";
import axios from "axios";
import Card from "components/Card/Card.jsx";
import StepZero from "components/GuidePage/StepZero.jsx";
import "assets/css/flipCards.css";
import lastGuideIcon from "assets/img/elements-28.png";
import fireCracker from "assets/img/fire-cracker.svg";
import withStyles from "@mui/styles/withStyles";
import {
  save_data,
  get_answers,
  isAuthenticated,
  ourFeedbackGreen,
  ourGreen,
  permissions,
} from "assets/jss/incept-sustainability-variables.jsx";
import Confetti from "react-confetti";
import goalicon from "assets/img/feedbackicon.svg";
import exampleicon from "assets/img/exampleicon.png";
import endmodule from "assets/img/endmodule.png";
import jwt from "jwt-decode";
import { useMediaQuery } from "react-responsive";
import { LifeCycleProvider } from "components/GuidePage/LifeCycleProvider.js";
import { default as CustomModal } from "components/Modal/Modal.jsx";
import ActionButton from "components/CustomButtons/ActionButton";
import Plausible from "plausible-tracker";
const { trackEvent } = Plausible();
import { CardImg } from "reactstrap";

//Translations
import { withTranslation, Trans } from "react-i18next";

/*Definition of styles*/
const style = {
  sectionManager: {
    paddingBottom: "15px",
  },
  visibleexer: {
    display: "none",
  },
  margAround: {
    padding: "40px 50px 60px 50px",
    minHeight: "90vh",
    "@media only screen and (max-width: 960px)": {
      padding: "0 20px 0 10px",
    },
  },
  dotimage: {
    maxWidth: "64px",
    borderWidth: "0",
    padding: "0",
    margin: "0",
    display: "block",
    "@media only screen and (max-width: 960px)": {
      maxWidth: "40px",
      marginBottom: "0",
    },
  },
  dotstep: {
    position: "relative",
    textAlign: "center",
  },
  dotimage2: {
    maxWidth: "64px",
    "@media only screen and (max-width: 960px)": {
      maxWidth: "40px",
    },
  },
  dotcentered: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  hidemobile: {
    "@media only screen and (max-width: 960px)": {
      display: "none",
    },
  },
  roottime: {
    "@media only screen and (max-width: 960px)": {
      padding: "0 10px",
    },
  },
  roottop: {
    marginTop: "35px",
    "@media only screen and (max-width: 960px)": {
      //marginTop: "30px",
    },
  },
  marginmobile: {
    margin: "20px 0",
    "@media only screen and (max-width: 960px)": {
      margin: "10px 0 0 0",
    },
  },
  marginmobile2: {
    margin: "20px 0",
    "@media only screen and (max-width: 960px)": {
      margin: "0 0 10px 0",
    },
  },
  marginmobile1: {
    "@media only screen and (max-width: 960px)": {
      marginRight: "10px",
    },
  },
  centermobile: {
    "@media only screen and (max-width: 960px)": {
      textAlign: "center",
    },
  },
  goalcard: {
    marginTop: "0",
    backgroundColor: ourFeedbackGreen,
    //border: "2px solid white",
    marginBottom: "0",
    "@media only screen and (max-width: 960px)": {
      marginBottom: "40px",
    },
  },
  iconFinish: {
    padding: "3px",
    height: "100%",
    width: "100%",
    maxWidth: "45px",
    maxHeight: "45px",
  },
  cardBodyText: {
    fontSize: ".95rem",
    "@media only screen and (max-width: 960px)": {
      marginRight: "10px",
    },
  },
  transitionClassBG: {
    transitionProperty: "background-color",
    transitionDuration: "1s",
    transitionTimingFunction: "linear",
    margin: "2rem auto 0 auto",
    padding: "1%",
    textAlign: "center",
    borderRadius: "50%",
    width: "auto",
    height: "auto",
    "@media only screen and (max-width: 960px)": {
      width: "45%",
    },
  },
  moduleCardWrapper1: {
    backgroundColor: "white",
  },
  moduleCardWrapper2: {
    backgroundColor: ourGreen,
  },
  moduleCardInnerWrapper1: {
    backgroundColor: "white",
    borderRadius: "50%",
    width: "100%",
    height: "100%",
    margin: "auto",
  },
};

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
    return <Component {...props} isMobile={mobile} />;
  };
}

const ContentExerciseBody = withStyles(style)((props) => {
  const { classes, goal, body } = props;
  return (
    <Grid
      container
      spacing={props.isMobile ? 0 : 3}
      style={{ padding: `${props.isMobile ? "0px 20px" : "auto"}` }}
    >
      <Grid
        item
        xs={12}
        md={goal != null && goal != "" ? 6 : 12}
        className={classes.cardBodyText}
      >
        {ReactHtmlParser(ReactHtmlParser(body))}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={
          goal != null && goal != ""
            ? classes.marginmobile1
            : classes.visibleexer
        }
      >
        <Card
          className={classes.goalcard}
          style={{ backgroundColor: ourFeedbackGreen }}
        >
          <Container style={{}}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} className={classes.centermobile}>
                <img
                  alt=""
                  src={goalicon}
                  width="60"
                  className={classes.marginmobile}
                />
              </Grid>
              <Grid item xs={12} md={10}>
                <div id="ideabox" className={classes.marginmobile2}>
                  {goal}
                </div>
              </Grid>
            </Grid>
          </Container>
        </Card>
      </Grid>
    </Grid>
  );
});

/*
 * Description: this component renders all the sections of a page
 * a section is a box that can contain for example an exercise, a video or other info
 */
var myTimeout;
class GuideSectionManager extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    var redlog = false;
    var decodedtoken = "";
    if (!isAuthenticated()) {
      redlog = true;
    } else decodedtoken = jwt(localStorage.getItem("token"));
    this.state = {
      writeModule: permissions.get(decodedtoken.role).WriteModule,
      loading: false,
      loading2: true,
      savedata: [],
      savedata_isupdated: [],
      prevdata: [], //needed for module 2,
      step: 0,
      postid: -1,
      task: 0,
      guide: 0,
      isdashactive: false,
      redirect: false,
      completedn: 0,
      completed: 0,
      nideas: 0,
      userid: decodedtoken !== "" ? decodedtoken.userid : "",
      showLeaveModal: 0, //0 means show nothing, 1 means show and final, and 2 show but is not final
      showCelebrationEndModuleModal: false,
      celebrationTransition: false,
      showErrorModal: 0,
      setScroll: 0,
      contentModalLeave: "",
      n_completed: 0,
      collaparr: [],
      itemsRef: [],
      colorTitle: props.colorTitle,
      labelsIdeas: [],
    };

    if (redlog) this.props.isExpired();

    this._renderSections = this._renderSections.bind(this);
    this._saveresults = this._saveresults.bind(this);
  }

  //Update when changing step
  //CAREFUL: Using any setState inside this life-cycle method will cause an infite loop.
  componentDidUpdate(prevProps, prevState) {
    //To avoid infinite loop, we ignore passing from "loading:true" to "loading:false" only
    if (prevState.loading && !this.state.loading) {
      return;
    }
  }

  componentDidMount() {
    //get answers
    this._isMounted = true;
    this.getanswers();
  }

  /*aux function*/
  clone(obj) {
    if (null == obj || "object" != typeof obj) return obj;
    var copy = obj.constructor();
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
    }
    return copy;
  }

  _isLastStep = () => {
	  return parseInt(this.props.stepn) === this.props.numPages;
  }

  handleCard(index, type, id, step, isLastSection) {
    if (
      type === "standard" ||
      type === "example" ||
      type === "interactive_example" ||
      type === "interactive_example_b"
    ) {
      this._saveresultslocal("ContentCard", 1, index, id);
    }
    this.props.lastOpened.step = parseInt(step);
    this.props.lastOpened.section = id;
    this.props.openedSection(index, step);

	// If this is the very last section of the module and all sections have been completed, opening it also completes
	if(isLastSection && this._isLastStep() && this._areAllSectionsCompleted()){
		this._saveresults(1);
    if (this.props.onCompleted){
      this.props.onCompleted();
    }
  
  }
}

  getanswers() {
    //get answers
    if (!isAuthenticated()) {
      if (this._isMounted) {
        this.props.isExpired();
      }
    }
    const token = localStorage.getItem("token"); //token needed
    const authstr = "Bearer " + token;
    axios
      .get(get_answers + this.props.guidnam + "/answers", {
        headers: { Authorization: authstr },
      })
      .then((response) => {
        const answersResponse = response.data.answers;
        this.setState({
          savedata: answersResponse,
          loading2: false,
          labelsIdeas: response.data.ideaslabels,
        });
        Object.keys(answersResponse).forEach((key) => {
          if (
            this.props.contentList[parseInt(answersResponse[key].step) - 1]
              .sections[key] !== undefined
          ) {
            this.props.contentList[
              parseInt(answersResponse[key].step) - 1
            ].sections[key].completed = true;
          }
        });
        this.props.answersCount(answersResponse);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading2: false });
      });
  }

  /**
   * Save results in state variable when making a change in exercise
   */
  _saveresultslocal = (type, results, index, exercise_id = "-1") => {
    if (!this.state.writeModule)
      //do not save anything is is manager/analytics view
      return;
    if (type === "ContentCard" && typeof results !== "undefined") {
      return;
    } else if (type === "ContentCard" && typeof results === "undefined") {
      results = 1;
    }

    let saveKey = "";
    if (exercise_id !== "-1") {
      this.props.contentList[this.props.stepn - 1].sections[
        exercise_id
      ].completed = true;
    }
    if (results == null || (exercise_id == null && type === "ContentCard"))
      return;
    var aux2 = {
      type: type,
      content: results,
      exercise_id: exercise_id,
      step: this.props.stepn,
    };
    this.step = this.props.stepn;
    this.task = index;
    this.guide = this.props.guidnam;
    this.postid = this.props.step.postid;
    this.recapPostid = this.props.RecapProps.postid;
    if (type === "Ideas") {
      saveKey = "cIdeas";
      aux2 = {
        type: type,
        content: results.idea,
        label: results.label,
        exercise_id: exercise_id,
        step: this.props.stepn,
      };
      this.state.savedata[saveKey] = aux2;
      this._updateresults(false, true, 0);
      this.props.answersCount(this.state.savedata);
    } else if (type === "learnings") {
      saveKey = "clearnings";
      this.state.savedata[saveKey] = aux2;
      this.state.savedata_isupdated[saveKey] = true;
    } else {
      saveKey = exercise_id;
      this.state.savedata[saveKey] = aux2;
      this.state.savedata_isupdated[saveKey] = true;
      console.log(this.state.savedata);
      this._updateresults(false, false, 0);
      this.props.answersCount(this.state.savedata);
    }
  };

  /**
   * _updateresults
   * * Save exercise answer
   * Redirect: if it goes to /guidespage
   * isidea: if what is saved is an idea
   * final: if the function was called from the "FINAL" modal (0,1)
   */
  _updateresults(redirect, isidea, final) {
    if (!isAuthenticated()) {
      this.props.isExpired();
    }
    if (!this.state.writeModule) {
      //do not save anything is is manager/analytics view
      //this.setState({ redirect: redirect, loading: false });
      this.setState({ loading: false });
      return;
    }

    var sendstep = this.step;
    if (isidea) {
      this.setState({ nideas: this.state.nideas + 1 });
      //indexidea = this.task;
    }

    var takstosave = [];
    var arraytosend = this.clone(this.state.savedata);

    for (let key in arraytosend) {
      //Check if exercise was updated and therefore, we will save it
      if (this.state.savedata_isupdated[key]) {
        if (arraytosend[key].type !== "learnings") takstosave.push(key);
        // => "1");
        else takstosave.push("Learnings"); // => "1");
        this.state.savedata_isupdated[key] = false;
      } else if (key === "cIdeas") {
        takstosave.push("Ideas");
      }
      //Change the format of array when is drag and drop, so it can be
      // saved in the backend
      if (arraytosend[key].type === "DragDropTwo_st") {
        let listToSaveLft = [];
        let listToSaveRght = [];
        for (let key2 in arraytosend[key].content) {
          arraytosend[key].content[key2].forEach(function (options) {
            if (options.dropListIndex === 0) listToSaveLft.push(options);
            else if (options.dropListIndex === 1) listToSaveRght.push(options);
          });
        }
        arraytosend[key].content2 = arraytosend[key].content;
        arraytosend[key].content = [...listToSaveLft, "", ...listToSaveRght];
        arraytosend[key].type = "DragDropTwo";
      } else if (
        arraytosend[key].type === "DragDropTwo" ||
        arraytosend[key].type === "DragDropTwo_ranking"
      ) {
        arraytosend[key].content2 = arraytosend[key].content;
      }
    }
    if (takstosave.length === 0 && !redirect) {
      return;
    }

    //data to send
    const user_id = this.state.userid; //userid
    const insdata = {
      userid: user_id,
      tosave: JSON.stringify({ ...arraytosend }),
      guide: this.guide,
      step: sendstep,
      postid: this.postid,
      task: "",
      exercise_id: this.state.exercise_id,
      ntasks: takstosave,
      recappostid: this.recapPostid,
      leavingModule: final,
      ifn: isidea ? this.task + this.state.nideas : 0,
    };

    if (isidea) {
      delete this.state.savedata["cIdeas"];
    }

    const token = localStorage.getItem("token"); //token needed
    const authstr = "Bearer " + token;
    //post
    this.setState({ loading: true }, () => {
      axios
        .post(save_data + this.props.guidnam + "/answers", insdata, {
          headers: { Authorization: authstr },
        })
        .then((res) => {
          this.setState({ loading: false });
        })
        .catch((err) => {
          console.log("Result: error ");
          console.log(err);
          this.setState({ loading: false, showErrorModal: 1 });
        });
    });
  }

  /*
   * Save results for when the user leaves the guide
   * Used only when user clicks "Finish"
   */
  _saveresults(type) {
    this.guide = this.props.guidnam;
    this._updateresults(true, false, type);
  }

  /**
   * Modals
   */
  handleShowLeaveModal = (final = 2) => {
    // If it is 1, means has finished module. 2 means is other case.
    this.setState({ isdashactive: true });

    //Here I have to add the content of how many activities are missing
    //Compare total exercises with exercises answered
    if (this.props.totalexercises !== this.props.totalexercises_ans) {
      var n_completed_aux = Math.floor(
        (100 * this.props.totalexercises_ans) / this.props.totalexercises
      );
      this.setState({ n_completed: n_completed_aux });
    }
    this.setState({ showLeaveModal: final });
    trackEvent("Go back to Dashboard");
  };

  handleHideLeaveErrorModal = () => {
    this.setState({ showErrorModal: 0 });
  };

  handleHideLeaveModal = (leavingModule = false) => {
    this.setState({ isdashactive: false });
    if (leavingModule) {
      //let realType = this.state.showLeaveModal === 1 ? 1 : 0;
      this._saveresults(1);
      this.setState({ isdashactive: true });
      this.setState({ redirect: true });
    }
    this.setState({ showLeaveModal: 0 });
  };

  handleShowCelebrationEndModuleModal = () => {
    this._saveresults(1);
    //Count Learnings when clicking finished button
    this.props.answersCount(this.state.savedata);
    this.setState({ showCelebrationEndModuleModal: true });
    myTimeout = setTimeout(
      function () {
        this.setState({ celebrationTransition: true });
      }.bind(this),
      100
    );
  };

  handleHideCelebrationEndModuleModal = () => {
    this.setState({
      showCelebrationEndModuleModal: false,
      celebrationTransition: false,
    });
    clearTimeout(myTimeout);
  };

  /** When user clicks on End in celebration modal
   * and should be redirected and save their
   */
  handleClickEndCelebrationEndModuleModal = () => {
    this.handleHideCelebrationEndModuleModal();
    //Should save learnings
    this.setState({ redirect: 1 });
  };

  /** When user clicks on Cancel in celebration modal
   * And should stay in the guide
   */
  handleClickCancelCelebrationEndModuleModal = () => {
    this.handleHideCelebrationEndModuleModal();
  };

  /**
   * Render
   */
  /*
   * Goes through the sections in the json and calls _renderSectionType
   * to render them
   */
  _renderSections(classes, { sections }, step) {
    return (
      <Timeline>
        {sections.map((obj, index) => {
          return (
            <TimelineItem key={obj.id}>
              <TimelineContent
                key={obj.id}
                classes={{ root: classes.roottime }}
              >
                <div
                  key={obj.id}
                  style={{ scrollMargin: "50px" }}
                  ref={(el) => {
                    this.state.itemsRef[index] = el;
                    return true;
                  }}
                ></div>
                {this._renderSectionType(
                  classes,
                  obj,
                  index,
                  step.stepn,
                  this.props.readContent[step.stepn - 1][index - 1],
				  index === sections.length - 1
                )}
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    );
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/admin/guides/:id" />;
    }
  };

  _areAllSectionsCompleted = () => {
    const stepNumber = parseInt(this.props.stepn);
    const numberOfSections = this.props.step.sections.length - 1; // -1 because the first section is the main heading itself
    const numberOfCompletedSections =
      this.props.completedSectionsStep[stepNumber - 1]; // -1 because of arra
    return numberOfCompletedSections >= numberOfSections;
  };

  /*
   * Checks what type of section is and renders de corresponding component
   */
  _renderSectionType(classes, obj, index, stepn, show, isLastSection) {
    const { type } = obj;
    const customProps = {
      title: obj.title,
      key: index + stepn,
      index: index,
      defaultView: obj.defaultView,
      isFirstSection: index === 0,
      textContent: ReactHtmlParser(ReactHtmlParser(obj.body)),
      videoContent: ReactHtmlParser(ReactHtmlParser(obj.bodyVideo)),
      showcard: show,
      stepn: stepn,
      smoothtrans: this.state.itemsRef[index],
      datasav: this.state.savedata[obj.id],
    };
    switch (type) {
      case "standard":
        let type2 = obj.subtype === "content_carousel" ? obj.subtype : obj.type;
        let images = obj.images !== undefined ? obj.images : "";
        let isitrich2 =
          obj.subtype === "content_carousel" || obj.isitrich ? true : false;
        return (
          <GuideStandardCardStyled
            type={type2}
            images={images}
            id={index + stepn}
            openSection={() => this.handleCard(index, type2, obj.id, stepn, isLastSection)}
            isitrich={isitrich2}
            colorTitle={this.state.colorTitle}
            saveresloc={() =>
              this._saveresultslocal(
                "ContentCard",
                this.state.savedata[obj.id],
                index,
                obj.id
              )
            }
            {...customProps}
          ></GuideStandardCardStyled>
        );
      case "flip_Cards":
        let type3 = obj.subtype === "content_carousel" ? obj.subtype : obj.type;
        let images2 = obj.images !== undefined ? obj.images : "";
        let isitrich3 =
          obj.subtype === "content_carousel" || obj.isitrich ? true : false;
        return (
          <GuideFlipCardsSection
            type={type3}
            cards={obj.cards}
            numberColumns={parseInt(obj.number_of_columns)}
            images={images2}
            id={index + stepn}
            openSection={() => this.handleCard(index, type3, obj.id, stepn, isLastSection)}
            isitrich={isitrich3}
            saveresloc={() =>
              this._saveresultslocal(
                "ContentCard",
                this.state.savedata[obj.id],
                index,
                obj.id
              )
            }
            {...customProps}
          ></GuideFlipCardsSection>
        );
      case "video":
        return (
          <GuideVideoCard
            key={index}
            id={index + stepn}
            title={obj.title}
            videoURL={obj.videoURL}
          />
        );
      case "example":
        return (
          <GuideExample
            openSection={() => this.handleCard(index, type, obj.id, stepn, isLastSection)}
            id={index + stepn}
            saveresloc={() =>
              this._saveresultslocal(
                "ContentCard",
                this.state.savedata[obj.id],
                index,
                obj.id
              )
            }
            {...customProps}
          ></GuideExample>
        );
      case "interactive_example":
        return (
          <GuideExampleInteractive
            isContent={obj.isContent !== undefined ? obj.isContent : false}
            id={index + stepn}
            defaultFirst={obj.firstboxdefault}
            openSection={() => this.handleCard(index, type, obj.id, stepn, isLastSection)}
            saveresloc={() =>
              this._saveresultslocal(
                "ContentCard",
                this.state.savedata[obj.id],
                index,
                obj.id
              )
            }
            boxes={obj.boxes}
            {...customProps}
          ></GuideExampleInteractive>
        );
      case "interactive_example_b":
        return (
          <GuideExampleInteractive_b
            isContent={obj.isContent !== undefined ? obj.isContent : false}
            id={index + stepn}
            inColumns={obj.incolumns}
            openSection={() => this.handleCard(index, type, obj.id, stepn, isLastSection)}
            boxes={obj.boxes}
            saveresloc={() =>
              this._saveresultslocal(
                "ContentCard",
                this.state.savedata[obj.id],
                index,
                obj.id
              )
            }
            colorBox={obj.color}
            {...customProps}
          ></GuideExampleInteractive_b>
        );
      case "exercise":
        return (
          <GuideExercise
            //ref="child" //this was giving problem with translation
            exercise={obj.exercise}
            id={obj.id}
            background_color={obj.background_color}
            type={obj.exercise.type}
            isFeedback={obj.goal != null}
            subtype={obj.exercise.subtype}
            saveresloc={this._saveresultslocal}
            indexk={index}
            openSection={() => this.handleCard(index, type, obj.id, stepn, isLastSection)}
            alldata={this.state.savedata}
            {...customProps}
          >
            <ContentExerciseBody body={obj.body} goal={obj.goal} />
          </GuideExercise>
        );
      case "withoutonetext":
        return (
          <WithoutOneStatic
            dimensions={obj.dimensions}
            body={obj.body}
            alldata={this.state.savedata}
          >
            {ReactHtmlParser(ReactHtmlParser(obj.body))}
          </WithoutOneStatic>
        );
      default:
        return "";
    }
  }

  /*Renders a page of the module*/
  render() {
    const {
      backNavPath,
      stepZero = {},
      step,
      stepn,
      guidenam,
      classes,
      numPages,
      totalexercises,
      RecapProps,
      contentList,
      lastOpened,
      totalexercises_ans,
      titleModule,
      modpdfurl,
      completedSectionsStep,
      basePath,
      colorTitle,
      t,
    } = this.props;
    const { title, postid, sections } = step;
    const {
      title: szTitle,
      introduction: szIntro,
      type: szType,
      complete_before: szcomplbef,
      module_length: szModL,
      img: szImg,
      learnings: szLearnings,
      feedbacks: szFeedbacks,
      steps: szSteps,
      acknowledgments: szacknow,
      references: szreferences,
      startLink,
    } = stepZero;
    const {
      showRecap,
      onHideRecap,
      contentWarning,
      showRecapModal,
      handleHideLeaveModalWarning,
      showWarning,
      ...otherRecapProps
    } = RecapProps;

    const guideNumber = titleModule; //5 will extract after "guide" word, example: guide1 -> 1

    if (this.state.loading2) {
      return <div></div>;
    }
    return (
      <div className={classes.sectionManager}>
        {this.renderRedirect()}
        <LifeCycleProvider>
          {this.state.showCelebrationEndModuleModal && (
            <Confetti
              run={this.state.showCelebrationEndModuleModal}
              numberOfPieces={400}
              style={{ zIndex: 9999 }}
              recycle={false}
            />
          )}

          <GuideRecap
            saveresloc={this._saveresultslocal}
            indexk={0}
            datasav={this.state.savedata["clearnings"]}
            showModal={showRecap}
            onHide={onHideRecap}
            labels={this.state.labelsIdeas}
            onFinishClick={() => {
              onHideRecap();
              this.handleShowCelebrationEndModuleModal();
            }}
            {...otherRecapProps}
          />

          <GuideTopContainer
            totalexercises={totalexercises}
            contentList={contentList}
            titleMod={this.props.moduleName}
            lastOpened={lastOpened}
            completedSectionsStep={completedSectionsStep}
            totalexercises_ans={totalexercises_ans}
            saveres={() => this.handleShowLeaveModal()}
            saveresloc={this._saveresultslocal}
            isdashactive={this.state.isdashactive}
            indexk={0}
            backNavPath={backNavPath}
            title={title}
            scroll={this.state.scroll}
            iskb={this.props.iskb}
            typemode={this.props.typemode}
            labels={this.state.labelsIdeas}
            showRecapModal={() => showRecapModal(this.props.iskb)}
            currentStep={parseInt(stepn) - 1}
          />

          <div
            style={{
              position: "relative",
              marginTop: this.props.isMobile ? "" : "-0.8rem",
              padding: this.props.isMobile ? "60px 0" : "",
            }}
          >
            <div
              className={classes.margAround}
              style={stepn === "0" ? { paddingTop: "20px" } : {}}
            >
              {stepn === "0" ? (
                <StepZero
                  colorTitle={colorTitle}
                  title={szTitle}
                  imageModule={szImg}
                  introduction={szIntro}
                  learnings={szLearnings}
                  feedbacks={szFeedbacks}
                  steps={szSteps}
                  type={szType}
                  acknowledgments={szacknow}
                  references={szreferences}
                  completebefore={szcomplbef}
                  modlength={szModL}
                  startLink={startLink}
                />
              ) : (
                this._renderSections(
                  classes,
                  { sections },
                  { stepn },
                  { postid }
                )
              )}
              {/*If last page, show end module button*/}
              {parseInt(stepn) >= 1 && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 10,
                    left:
                      parseInt(stepn) === parseInt(numPages) &&
                      this.props.isMobile
                        ? "50%"
                        : "20px",
                    transform:
                      parseInt(stepn) === parseInt(numPages) &&
                      this.props.isMobile
                        ? "translateX(-50%)"
                        : "none",
                    maxWidth: "1280px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    textAlign:
                      parseInt(stepn) === parseInt(numPages) &&
                      this.props.isMobile
                        ? "center"
                        : "right",
                  }}
                >
                  <ActionButton
                    backGround={false}
                    style={{ border: stepn === "1" ? "1px solid grey" : "" }}
                    disabled={stepn === "1"}
                    size={this.props.isMobile ? "small" : "large"}
                    centered
                    component={Link}
                    to={
                      this.props.iskb
                        ? `${backNavPath}/${parseInt(stepn) - 1}/${
                            this.props.typemode
                          }/`
                        : `${backNavPath}/${parseInt(stepn) - 1}/act/`
                    }
                    onClick={() =>
                      trackEvent("Go to previous page/step button")
                    }
                  >
                    {t("Previous step")}
                  </ActionButton>
                </div>
              )}
              {parseInt(stepn) !== 0 &&
              parseInt(stepn) !== parseInt(numPages) ? (
                <div
                  style={{
                    position: "absolute",
                    bottom: 10,
                    right: "20px",
                    maxWidth: "1280px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    textAlign: "right",
                  }}
                >
                  <ActionButton
                    key={stepn} //to force re-render
                    size={this.props.isMobile ? "small" : "large"}
                    centered
                    // Disabled if not all steps are completed
                    disabled={!this._areAllSectionsCompleted()}
                    component={Link}
                    to={
                      this.props.iskb
                        ? `${backNavPath}/${parseInt(stepn) + 1}/${
                            this.props.typemode
                          }/`
                        : `${backNavPath}/${parseInt(stepn) + 1}/act/`
                    }
                    onClick={() => trackEvent("Go to next page/step button")}
                  >
                    {t("Next step")}
                  </ActionButton>
                </div>
              ) : (
                parseInt(stepn) === parseInt(numPages) && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 10,
                      right: "20px",
                      maxWidth: "1280px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      paddingLeft: "24px",
                      paddingRight: "24px",
                      textAlign: "right",
                    }}
                  >
      
                    <ActionButton
                      size="large"
                      centered
                      disabled={!this._areAllSectionsCompleted()}
                      onClick={() => showRecapModal(this.props.iskb)}
                    >
                      {t("End Module")}
                      <img
                        src={endmodule}
                        title="End module"
                        alt="end module"
                        className={classes.iconFinish}
                      />
                    </ActionButton>
                  </div>
                )
              )}
            </div>
          </div>
        </LifeCycleProvider>
        <CustomModal
          show={this.state.showErrorModal === 1}
          onHide={this.handleHideLeaveErrorModal}
        >
          <h5
            style={{
              textAlign: "center",
              marginTop: "50px",
              marginBottom: "50px",
            }}
          ></h5>
          <div>
            <h2>Well, this was unexpected. </h2>
            <p>
              Sorry, something is not working properly. We now know about it and
              are working to fix it.
            </p>
            <p>
              In the meantime, here is what it can be done:
              <ul>
                <li>Refresh the page (sometimes this helps).</li>
                <li>Make sure you have internet connection.</li>
                <li>Try again in 30 minutes.</li>
                <li>
                  Email us at inceptsustainability@ramboll.com and tell us what
                  happened.
                </li>
              </ul>
            </p>
          </div>
        </CustomModal>
        <CustomModal
          show={
            this.state.showLeaveModal === 1 || this.state.showLeaveModal === 2
          }
          onHide={this.handleHideLeaveModal}
        >
          <h5
            style={{
              textAlign: "center",
              marginTop: "50px",
              marginBottom: "50px",
            }}
          >
            {t("Are you sure you want to leave?")}
          </h5>
          {this.props.totalexercises !== this.props.totalexercises_ans && (
            <p style={{ textAlign: "center" }}>
              <Trans
                i18nKey="leavingDashboard" // optional -> fallbacks to defaults if not provided
                defaults="So far you have completed {{n_completed}}% of the module. You are missing activities in step(s): 
					{{missingExercises}}.
					If you choose to leave, your current progress is saved for next time." // optional defaultValue -> <0> represents the index in "components" array which makes it easy
                values={{
                  n_completed: this.state.n_completed,
                  missingExercises: this.props.missingExercises,
                }} // useful when you need to add dynamic data in form of variables and not components
              />
            </p>
          )}
          <Grid container spacing={2}>
            <Grid item sm style={{ textAlign: "right" }}>
              <ActionButton
                size="large"
                onClick={() => this.handleHideLeaveModal(true)}
              >
                {t("Yes")}
              </ActionButton>
            </Grid>
            <Grid item sm>
              <ActionButton
                size="large"
                lighten
                onClick={() => this.handleHideLeaveModal()}
              >
                {t("Cancel")}
              </ActionButton>
            </Grid>
          </Grid>
        </CustomModal>
        <CustomModal
          show={this.state.showCelebrationEndModuleModal}
          onHide={this.handleHideCelebrationEndModuleModal}
        >
          {/*<img alt="" src={fireCracker} width={100} style={{margin:'0 auto'}}/>*/}
          <div
            className={
              classes.transitionClassBG +
              " " +
              (this.state.celebrationTransition
                ? classes.moduleCardWrapper2
                : classes.moduleCardWrapper1)
            }
          >
            {" "}
            <div className={classes.moduleCardInnerWrapper1}>
              <img
                src={this.props.moduleImg}
                alt="Module image"
                width="150px"
              />
            </div>
          </div>
          <div style={{ textAlign: "center", marginBottom: "50px" }}>
            <h1 style={{ marginTop: "50px" }}>{t("Congratulations!")}</h1>
            <h5
              style={
                modpdfurl !== undefined && modpdfurl !== ""
                  ? {}
                  : { marginBottom: "50px" }
              }
            >
              {t("You have finished the module number x", {
                guideNumber: guideNumber,
                defaultValue: "You have finished the module {{guideNumber}}",
              })}
            </h5>
            {modpdfurl !== undefined && modpdfurl !== "" && (
              <a href={modpdfurl} style={{ fontSize: "1rem" }} target="_blank">
                Download Module PDF
              </a>
            )}
          </div>
          <Grid container spacing={2}>
            <Grid item sm style={{ textAlign: "right" }}>
              <ActionButton
                size="large"
                onClick={this.handleClickEndCelebrationEndModuleModal}
              >
                {t("End")}
              </ActionButton>
            </Grid>
            <Grid item sm>
              <ActionButton
                size="large"
                lighten
                onClick={this.handleClickCancelCelebrationEndModuleModal}
              >
                {t("Cancel")}
              </ActionButton>
            </Grid>
          </Grid>
        </CustomModal>
        <CustomModal show={showWarning} onHide={handleHideLeaveModalWarning}>
          <p style={{ textAlign: "center" }}>
            {contentWarning !== "" && (
              <span style={{ textAlign: "center" }}>
                <Trans
                  i18nKey="goingToWrapUp" // optional -> fallbacks to defaults if not provided
                  defaults="You are missing activities in step(s): {{contentWarningtext}}. The Wrap-Up will be available when you have finished these activities." // optional defaultValue -> <0> represents the index in "components" array which makes it easy
                  values={{ contentWarningtext: contentWarning }} // useful when you need to add dynamic data in form of variables and not components
                />
              </span>
            )}
          </p>
          <Grid container spacing={2} style={{ textAlign: "center" }}>
            <Grid item sm>
              <ActionButton size="large" onClick={handleHideLeaveModalWarning}>
                {t("Back to Module")}
              </ActionButton>
            </Grid>
          </Grid>
        </CustomModal>
      </div>
    );
  }
}

export default withRouter(
  withTranslation()(withMyHook(withStyles(style)(GuideSectionManager)))
);