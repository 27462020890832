import React, { useState, useEffect, useCallback } from 'react'
import jwt from "jwt-decode";
import NewAppIFrame from 'components/NewAppIFrameViewer';
import clientConfig from 'client-config.js';

const ConsultRamboll = () => {
    const [language, setLanguage] = useState(() => localStorage.getItem('i18nextLng') || 'en');
    const token = localStorage.getItem('token');

    const jwtToken = token ? jwt(token) : null;

    // Effect to initialize language
    useEffect(() => {
      const storedLanguage = localStorage.getItem('i18nextLng');
      if (storedLanguage && storedLanguage !== language) {
        setLanguage(storedLanguage);
      }
    }, []);

    const handleLanguageChange = useCallback((sendMessage) => {
        const languageChangeListener = (event) => {
          if (event.key === 'i18nextLng') {
            const newLanguage = event.newValue;
            setLanguage(newLanguage);
            sendMessage('language', newLanguage);
          }
        };
    
        // Add event listener for storage changes
        window.addEventListener('storage', languageChangeListener);
    
        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('storage', languageChangeListener);
      }, []);
    
      // This is needed so that logout doesnt break the app
      if (!jwtToken){
        return null;
      }

      /* TODO:  UNCOMMENT WHEN issue with 401 unauthorized is fixed in the new app when POSTing data */
      return (
        <NewAppIFrame
          iFrameOrigin={clientConfig.nextUrl} // Production Mode ----
          //iFrameOrigin={'http://localhost:3010'} // Development Mode ----
          iFramePagePath={`consult-ramboll`}
          title="Consult Ramboll"
          authToken={token}
          userId={jwtToken }
          language={language}
          onLanguageChange={handleLanguageChange}
        />
      )
    
}

export default ConsultRamboll